import Axios from 'axios'
import {addSalesforceCampaignMember, getSalesforceCampaignMember} from '@utils/ApiUrls';

const subscribeUser = async (apiUrl:string , firstName:string, lastName:string, email:string, source:string, salesforceCampaignName:string, withEmail: boolean) =>{
    const url = addSalesforceCampaignMember(apiUrl);
    const result = await Axios.post(url, {
        CampaignName: salesforceCampaignName,
        CampaignMember: {
            FirstName: firstName,
            LastName: lastName,
            Email: email
        },
        Source: source,
        WithEmail: withEmail
    });

    return result.data.Data;
}

const subscribeUserRequest = async (apiUrl:string , values:any, source:string, campaignValues:any, withEmail: boolean, vehicleType?: string, vehicleMake?: string) =>{    

        var campaignMember = {
            FirstName: values.firstname,
            LastName: values.lastname,
            Email: values.email,
            Phone: values.phone,
            Address__c: values.address,
            ReferralEmail__c: values.referral_email,
            ReferralName__c: values.referral_name,
            OwnPlate__c: values.own_a_plate,
            LikelyToBuy__c: values.likely_to_buy,
            Gender__c: values.gender,
            CurrentPlateCombination__c: values.current_plate_combination,
            JobTitle__c: values.job_title,
            HasOptedOutOfEmail: values.hasOptedOutOfEmail,
            VehicleMake__c: vehicleMake,
            VehicleType__c: vehicleType,
        }
    
        var campaign = {
            Name: campaignValues.campaignName,
            StartDate: campaignValues.validFrom,
            EndDate: campaignValues.expiredOn,
            IsActive: (new Date() >= new Date(campaignValues.validFrom) && new Date() <= new Date(campaignValues.expiredOn)),
            Transaction_Types__c: campaignValues.transactionTypes,
            KPI_Campaign_Id__c: campaignValues.campaign_id__c
        }
        const url = addSalesforceCampaignMember(apiUrl);
        const result = await Axios.post(url, {
            Campaign: campaign,
            CampaignMember: campaignMember,
            Source: source,
            WithEmail: withEmail
        });
    
        return result.data.Data;
    }

const getSubscriber = async (apiUrl:string , email:string, source:string, salesforceCampaignName:string) =>{
    const url = getSalesforceCampaignMember(apiUrl, salesforceCampaignName, email);
    const result = await Axios.get(url);

    return result.data.Data;
}

export const SubscriptionService = {
    subscribeUser: subscribeUser,
    getSubscriber: getSubscriber,
    subscribeUserRequest: subscribeUserRequest
}